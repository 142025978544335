<template>
    <div>
        <div class="forgot-password-area">
            <div class="d-flex align-items-center justify-content-center h-100 forgot-password-icon">
            <img alt="Logo" src="media/logos/forgot-password.png" class="h-45px" />
                
            </div>
            <div class="text-center forgot-password">
                Reset Password
            </div>
            <div class="text-center forgot-description">
                Verify your registered Username associated with your account
            </div>
        </div>
        <el-form
            ref="ruleFormNameRef"
            :model="reset"
            :rules="rules"
            label-position="top"
        >
            <el-form-item prop="email">
                <!-- <label for="user-email"> Username </label> -->
                <el-input
                    id="user-email"
                    v-model="reset.email"
                    placeholder="Enter your username"
                    ref="email"
                    maxlength="50"
                    :disabled="reset.hasPopulate"
                    @input="Validations.user_name_not_found = false; Validations.user_name_not_active = false; Validations.unauthorized = false"
                    @keydown.prevent.enter="sendCode"
                >
                </el-input>
            </el-form-item>
        </el-form>

        <div class="d-flex justify-content-center">
            <button
                type="button"
                class="btn btn-lg btn-info w-50 mt-5"
                @click="sendCode"
                :disabled="buttonIconDisabled || reset.email.length == 0"
            >
                Continue
            </button>
        </div>
    </div>
</template>

<script setup>
    import Validations from '@/core/services/etc/Validations'
    import { reactive, computed, ref } from 'vue';
    import { useStore } from 'vuex'
    import { notif } from "@/store/stateless/store";
    import { useUpdateCustomerData } from '@/store/composable/Customer'
    
    const store = useStore()
    const ruleFormNameRef = ref()
    const buttonIconDisabled = ref(false)
    const email = ref()

    const reset = computed(() => {
        return store.state.AuthModule.resetPassword
    })

    const rules = reactive({
        email: [{ validator: Validations.username, trigger: ['blur', 'change'] }]
    })

    async function sendCode() {
        buttonIconDisabled.value = true
        const response = await useUpdateCustomerData({
            linkType: 'request-reset-password',
            email: reset.value.email,
            app: 'misc'
        })

        if(response.status < 299) {
            notif.simple(
                        "Reset Password",
                        "success",
                        "Code has been sent to your email",
                        4500,
                        "notification-success"
                    );

                    store.commit('SET_RESET_PASSWORD_NAVIGATION', true)
            
        } else {
            buttonIconDisabled.value = false

            if(response.response.data.data.not_found) {
                Validations.user_name_not_found = true
            } else if (response.response.data.data.not_active) {
                Validations.user_name_not_active = true;
            } else if (response.response.data.data.unauthorized) {
                Validations.unauthorized = true;
            } 

            ruleFormNameRef.value.validateField('email')
            email.value.focus()
        }
    }

</script>
<style scoped>
    .forgot-password-area {
        margin-bottom: 35px;
    }

    /* .forgot-password-icon {
        margin-top: 8vh;  
    } */

    .forgot-password {
        font-size: 25px;
        font-weight: 500;
        color: #222261;
        line-height: 28px;
        margin: 12px;
        word-break: break-word;
    }
    .forgot-description {
        margin: 10px;
        word-break: break-word;
    }

</style>